<template>
    <div class="container news-page">
        <div class="common-channel-crumb">
            <span>当前位置：</span><a>首页</a><i>&gt;</i><strong>交通标志</strong>
        </div>
        <div class="frame-l-r clearfix">
            <div class="left">
                <div class="com-tree-w">
                    <ul class="level-1" v-for="item in cates" :key="item.title">
                        <li>
                            <a class="title" :class="{'active': item.title === activeCategory}"
                                    @click="topLevelClick(item)">
                                {{item.title}}
                            </a>

                            <ul class="level-2" v-if="item.children">
                                <li v-for="(cate, index) in item.children" :key="index"
                                        @click="subLevelClick(cate)">
                                    <a class="title" :class="{'active': cate.title === activeCategory}">{{cate.title}}</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="right">
                <div class="com-sign-container com-part">

                    <h3 class="part-title">{{activeCategory}}</h3>

                    <ul class="clearfix">
                        <li v-for="(item, index) in signList" :key="index" :title="item.desc">
                            <div class="img-div">
                                <img :src="item.image" :alt="item.title" />
                            </div>
                            <p class="title">{{item.title}}</p>
                        </li>
                    </ul>

                    <div class="com-image-show hide">
                        <div class="mengban"></div>
                        <div class="dialog-box">
                            <div class="close">×</div>
                            <div class="icon-left hide"></div>
                            <div class="icon-right hide"></div>
                            <div class="top"></div>
                            <div class="img-bg" style="background-image: url()"></div>
                            <div class="content">
                                <p class="title"></p>
                                <p class="desc"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import signs from '@/mock/signs.json';

const cateMap = {};
const cates = signs.reduce((ret, sign) => {
    let tag = sign.tag;

    if (tag) {
        let cate = cateMap[tag];

        if (!cate) {
            cate = cateMap[tag] = {
                title: tag,
                children: [{
                    title: sign.cate
                }]
            };

            cateMap[sign.cate] = {title: sign.cate};

            ret.push(cate);
        }
        else {
            let subCate = cateMap[sign.cate];

            if (!subCate) {
                cateMap[sign.cate] = {
                    title: sign.cate
                };
                cate.children.push({
                    title: sign.cate
                });
            }
        }
    }
    else {
        let cate = cateMap[sign.cate];

        if (!cate) {
            cate = cateMap[sign.cate] = {
                title: sign.cate
            };
            ret.push(cate);
        }
    }

    return ret;
}, []);

export default defineComponent({

    data: function () {
        return {
            signs: signs,
            cates: cates,
            activeCategory: cates[0].children ? cates[0].children[0].title : cates[0].title
        };
    },

    computed: {
        signList: function () {
            let activeCategory = this.activeCategory;

            return this.signs.filter((sign) => {
                return sign.cate === activeCategory;
            });
        }
    },

    methods: {

        topLevelClick: function (cate) {
            if (cate.children) {
                return;
            }
            else {
                this.subLevelClick(cate);
            }
        },

        subLevelClick: function (cate) {
            this.activeCategory = cate.title;
        }
    }
});
</script>

<style scoped>
.container .frame-l-r>.left {
    width: 200px !important
}

.container .frame-l-r>.right {
    width: 982px !important
}

.container .frame-l-r>.right .com-part {
    margin-top: 10px
}

.com-tree-w.flex {
    position: fixed;
    top: 0;
    width: 200px
}

.com-tree-w>.level-1 {
    line-height: 38px
}

.com-tree-w>.level-1>li>.title.off:before,
.com-tree-w>.level-1>li>.title.on:before {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 18px;
    margin-left: -28px;
    margin-right: 8px;
    border: 1px solid #e9e9e9;
    text-align: center;
    color: #999
}

.com-tree-w>.level-1>li {
    padding: 10px 0 10px 28px;
    border-bottom: 1px solid #e9e9e9
}

.com-tree-w>.level-1>li>.title {
    font-weight: 700;
    font-size: 16px
}

.com-tree-w>.level-1>li>.title.on:before {
    content: '-'
}

.com-tree-w>.level-1>li>.title.off:before {
    content: '+'
}

.com-tree-w .level-2>li>.title {
    display: block;
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.com-tree-w .title.active {
    color: #37B5F8
}

.com-sign-container>ul {
    margin-left: -20px
}

.com-sign-container>ul>li {
    float: left;
    width: 230px;
    height: 175px;
    margin-left: 20px;
    margin-top: 20px;
    padding: 2px 2px 0;
    text-align: center;
    background-color: #e6e6e6;
    cursor: pointer
}

.com-sign-container>ul>li:hover {
    background-color: #37B5F8;
    color: #fff
}

.com-sign-container>ul>li .img-div {
    width: 100%;
    height: 130px;
    padding-top: 18px;
    background-color: #fff;
    overflow: hidden
}

.com-sign-container>ul>li .img-div img {
    width: auto;
    height: auto;
    margin: 0 auto;
    display: block;
    max-height: 120px;
    max-width: 100px
}

.com-sign-container>ul>li .title {
    line-height: 45px;
    float: 16px;
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-sign-container .police-wapper {
    padding: 20px 100px 100px
}

.com-sign-container .police-wapper p {
    margin: 20px 0
}

.com-sign-container .police-wapper img {
    display: inline-block
}

.com-sign-container .police-wapper img+img {
    margin: 0 10px
}

.com-sign-container .police-wapper h1 {
    display: none
}

.com-sign-container .shigu-ul-container {
    padding-top: 20px
}

.com-sign-container .shigu-ul-container li {
    text-align: center;
    padding: 20px 0;
    margin-bottom: 30px
}

.com-sign-container .shigu-ul-container img {
    width: 350px;
    height: auto;
    display: block;
    margin: 10px auto
}

.com-sign-container .shigu-ul-container span {
    font-size: 17px;
    color: #3c3c3c;
    display: block;
    margin: 0 auto
}

.com-sign-container .shigu-ul-container b {
    text-align: left;
    font-size: 15px;
    display: block;
    text-indent: 2em;
    margin-top: 15px;
    padding: 0 40px;
    font-weight: 400
}

.com-image-show,
.com-image-show>.mengban {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}

.com-image-show>.dialog-box>.content .desc,
.com-image-show>.dialog-box>.content .title {
    font-family: "Microsoft YaHei", "Hiragino Sans GB", "Hiragino Sans GB W3", "Helvetica Neue", Helvetica, Arial, sans-serif
}

.com-image-show>.mengban {
    background-color: rgba(0, 0, 0, .5)
}

.com-image-show>.dialog-box {
    position: absolute;
    width: 600px;
    height: 600px;
    left: 50%;
    top: 50%;
    margin-left: -300px;
    margin-top: -300px;
    background-color: #fff
}

.com-image-show>.dialog-box>.close {
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 32px;
    color: #999;
    cursor: pointer
}

.com-image-show>.dialog-box>.icon-left {
    position: absolute;
    left: 0;
    top: 200px;
    width: 36px;
    height: 60px;
    cursor: pointer;
    background: url("../assets/files/70fddb895844580b1a437231f6ea3790.png") center no-repeat #e5e5e5;
    background-size: 50%
}

.com-image-show>.dialog-box>.icon-left:hover {
    background-color: #bbb
}

.com-image-show>.dialog-box>.icon-right {
    position: absolute;
    right: 0;
    top: 200px;
    width: 36px;
    height: 60px;
    cursor: pointer;
    background: url("../assets/files/7e256b783e0a3b74bdbf93ee3273f530.png") center no-repeat #e5e5e5;
    background-size: 50%
}

.com-image-show>.dialog-box>.icon-right:hover {
    background-color: #bbb
}

.com-image-show>.dialog-box>.img-bg {
    width: 500px;
    height: 400px;
    margin: 20px auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain
}

.com-image-show>.dialog-box>.content {
    line-height: 28px;
    margin: 0 20px
}

.com-image-show>.dialog-box>.content .title {
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    border-bottom: 1px solid #e9e9e9
}

.com-image-show>.dialog-box>.content .desc {
    margin: 10px;
    font-size: 16px
}
</style>