
import {defineComponent} from 'vue';

import signs from '@/mock/signs.json';

const cateMap = {};
const cates = signs.reduce((ret, sign) => {
    let tag = sign.tag;

    if (tag) {
        let cate = cateMap[tag];

        if (!cate) {
            cate = cateMap[tag] = {
                title: tag,
                children: [{
                    title: sign.cate
                }]
            };

            cateMap[sign.cate] = {title: sign.cate};

            ret.push(cate);
        }
        else {
            let subCate = cateMap[sign.cate];

            if (!subCate) {
                cateMap[sign.cate] = {
                    title: sign.cate
                };
                cate.children.push({
                    title: sign.cate
                });
            }
        }
    }
    else {
        let cate = cateMap[sign.cate];

        if (!cate) {
            cate = cateMap[sign.cate] = {
                title: sign.cate
            };
            ret.push(cate);
        }
    }

    return ret;
}, []);

export default defineComponent({

    data: function () {
        return {
            signs: signs,
            cates: cates,
            activeCategory: cates[0].children ? cates[0].children[0].title : cates[0].title
        };
    },

    computed: {
        signList: function () {
            let activeCategory = this.activeCategory;

            return this.signs.filter((sign) => {
                return sign.cate === activeCategory;
            });
        }
    },

    methods: {

        topLevelClick: function (cate) {
            if (cate.children) {
                return;
            }
            else {
                this.subLevelClick(cate);
            }
        },

        subLevelClick: function (cate) {
            this.activeCategory = cate.title;
        }
    }
});
